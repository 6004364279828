import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox, { Modal, ModalGateway } from 'react-images';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import Layout from '../components/layout';
import SEO from '../components/seo';

const GalleryTemplate = ({ data }) => {
  let images = data.images.blocks[0].attrs.media;
  const thumbnails = data.thumbnails.blocks[0].attrs.media;
  let gallery = images.map((image, index) => {
    return {
      thumbnail: thumbnails[index].url.localFile.childImageSharp.fluid,
      // caption: caption,
      index: index,
      src: image.url.localFile.childImageSharp.fluid.src,
    };
  });

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const gotoNext = () => setLightboxIndex(lightboxIndex + 1);
  const gotoPrevious = () => setLightboxIndex(lightboxIndex - 1);
  const closeLightbox = () => setLightboxOpen(false);
  const handleThumbnailClick = index => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };
  return (
    <Layout hasRecentWork={false}>
      <SEO title="Gallery" />
      <div className="container">
        <div css={imagesGridStyle}>
          {gallery.map((image, index) => (
            <div
              css={imagesGridContainerStyle}
              onClick={() => handleThumbnailClick(index)}
              key={index}
            >
              <Img fluid={image.thumbnail} />
              {/* <div
              css={imagesGridItemStyle}
              onClick={() => handleThumbnailClick(index)}
              style={{ backgroundImage: `url(${image.src})` }}
            /> */}
            </div>
          ))}
        </div>
      </div>
      <ModalGateway>
        {lightboxOpen ? (
          <Modal onClose={closeLightbox}>
            <Lightbox
              views={gallery}
              currentIndex={lightboxIndex}
              onClickPrev={gotoPrevious}
              onClickNext={gotoNext}
              showImageCount={false}
              closeOnBackdropClick={true}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  );
};

GalleryTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default GalleryTemplate;

export const query = graphql`
  query {
    images: wordpressPage(slug: { eq: "gallery" }) {
      id
      blocks {
        attrs {
          media {
            url {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }

    thumbnails: wordpressPage(slug: { eq: "gallery" }) {
      id
      blocks {
        attrs {
          media {
            url {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const imagesGridStyle = css`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 2rem 0;
  /* justify-content: space-evenly; */
  /* padding: 2rem; */
  @media (max-width: 640px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const imagesGridContainerStyle = css`
  /* border: 1px solid gray; */
  /* padding: 0.5rem; */
  width: 100%;
  /* padding: 1rem; */
  /* height: 28vw; */
  cursor: pointer;
  /* @media (max-width: 900px) {
    width: 42vw;
    height: 42vw;
  }
  @media (max-width: 640px) {
    width: 90vw;
    height: 90vw;
    margin: 2rem 0 0;
  } */
`;
